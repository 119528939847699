<p>Thank you for submitting a request dated {{form.date}} requesting {{form.requestType}} under the provisions of the
    CCPA.
</p>
<p><b>In order to verify your request, please reply back to this email.</b> As a reminder, here is the information
    you submitted to us:</p>

Name: {{form.firstName}} {{form.lastName}}<br>
Email: {{form.email}}<br>
State of residence: {{form.state}}<br>
Nature of request: {{form.requestType}}<br>
Authorized consent on behalf of consumer: {{form.check}}<br>
Unique code: {{form.randomCode}}<br><br>

Yours sincerely,<br>
Rush Limbaugh<br>
privacy@rushlimbaugh.com<br><br>

Rush Limbaugh: <a href="https://www.rushlimbaugh.com/ ">https://www.rushlimbaugh.com/ </a><br>
Help: <a href="https://help.rushlimbaugh.com/">https://help.rushlimbaugh.com/</a> <br>
Privacy: <a href="https://www.premierenetworks.com/pages/legal.aspx#privacystatement">https://www.premierenetworks.com/pages/legal.aspx#privacystatement
</a><br>
Terms: <a href="https://www.premierenetworks.com/pages/legal.aspx#termsofuse">https://www.premierenetworks.com/pages/legal.aspx#termsofuse</a>
<br>