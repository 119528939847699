import { Routes } from "@angular/router";

const loadIheart = () =>
  import("./iheart/iheart.module").then(mod => mod.IheartModule);
const loadRush = () => import("./rush/rush.module").then(mod => mod.RushModule);
const loadCoast = () =>
  import("./coast/coast.module").then(mod => mod.CoastModule);

export const features: Routes = [
  {
    path: "iheartradio/ca",
    loadChildren: loadIheart
  },
  {
    path: "rushlimbaugh/ca",
    loadChildren: loadRush
  },
  {
    path: "coasttocoastam/ca",
    loadChildren: loadCoast
  }
];
