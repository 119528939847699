import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { features } from "./features";

const routes: Routes = [
  { path: "", redirectTo: "/iheartradio/ca/login", pathMatch: "full" },
  { path: "", children: [...features] },
  { path: "**", redirectTo: "/iheartradio/ca/login", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
