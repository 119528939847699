import { Component, ElementRef } from "@angular/core";

@Component({
  selector: "heart-email-template",
  templateUrl: "./email-template.component.html",
  styleUrls: ["./email-template.component.scss"]
})
export class EmailTemplateComponent {
  form: any;
  constructor(public elRef: ElementRef) {}

  getHtmlContent() {
    return this.elRef.nativeElement.innerHTML;
  }
}
