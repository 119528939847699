import * as fromRouter from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";

import { RouterStateUrl } from "./route-serializer";

interface State {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

const selectRouter = createFeatureSelector<
  State,
  fromRouter.RouterReducerState<RouterStateUrl>
>("router");

const selectRouterState = createSelector(selectRouter, router => router.state);

export const selectQueryParams = createSelector(
  selectRouterState,
  state => state.queryParams
);

export const selectQueryParam = (param: string) =>
  createSelector(selectQueryParams, params => params && params[param]);

export const selectRouteParams = createSelector(
  selectRouterState,
  state => state.params
);

export const selectRouteParam = (param: string) =>
  createSelector(selectRouteParams, params => params && params[param]);

export const selectRouteData = createSelector(
  selectRouterState,
  state => state.data
);

export const selectUrl = createSelector(selectRouterState, state => state.url);
