import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { StoreModule } from "@ngrx/store";
import { StoreRouterConnectingModule, RouterState } from "@ngrx/router-store";
import { EffectsModule } from "@ngrx/effects";

import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import * as fromStore from "./store";
import { extModules } from "./build-specifics";
import { EmailTemplateComponent } from "./components";
import { SafeModule } from "./shared/safe";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SafeModule,
    StoreModule.forRoot(fromStore.ROOT_REDUCERS, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true
      }
    }),
    EffectsModule.forRoot([fromStore.RouteEffects]),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      serializer: fromStore.CustomRouterSerializer
    }),
    extModules,
    AppRoutingModule
  ],
  declarations: [AppComponent, EmailTemplateComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
