import { RouterReducerState, routerReducer } from "@ngrx/router-store";
import { InjectionToken } from "@angular/core";
import { ActionReducerMap, Action, createFeatureSelector } from "@ngrx/store";

export interface State {
  router: RouterReducerState<any>;
}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>("Root reducers token", {
  factory: () => ({
    router: routerReducer
  })
});
