import { Injectable, Inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ROUTER_NAVIGATED } from "@ngrx/router-store";
import { map, switchMap } from "rxjs/operators";
import { State } from "../reducers";
import { Store } from "@ngrx/store";
import { selectUrl } from "./router.store";
import { DOCUMENT } from "@angular/common";

@Injectable()
export class RouteEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) {}

  ROUTER_NAVIGATED$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        switchMap(_ => this.store.select(selectUrl)),
        map(url => {
          let href = "/assets/images/favicon_heart.ico";
          if (url.includes("iheartradio")) {
            href = "/assets/images/favicon_heart.ico";
          }
          if (url.includes("coasttocoastam")) {
            href = "/assets/images/favicon_coast.ico";
          }
          if (url.includes("rushlimbaugh")) {
            href = "/assets/images/favicon_rush.png";
          }
          this.document.getElementById("appFavicon").setAttribute("href", href);
          return url;
        })
      ),
    { dispatch: false }
  );
}
