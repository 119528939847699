import { Component, OnInit } from "@angular/core";

declare var IHR: any;

@Component({
  selector: "heart-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    IHR.initialize({
      container: "iheart-login", // the id of element to render form into (NOTE: needs to be an id)
      alwaysPrompt: true,
      // if true, then force authentication even if user is already authenticated (defaults is false)
      piiMicrosite: true, // NEW PARAM
      hostName: "ccpa-login", // for tracking purposes
      amp: "https://us.api.iheart.com/api/", // iHeart amp api to use, for ccpa, we will
      host: "https://www.iheart.com"
    });
  }
}
